import { FaFacebook, FaInstagram, FaTelegram, FaTwitter } from 'react-icons/fa'
import { Motto, SPT2 } from '../../../assets'
import './Footer.scss'
const Footer = () => {
  return (
    <section className="_footer">
      <div className="container">
        <div className="wrapper">
          <div className="motto">
            <div className="top">
              <div className="image-wrapper">
                <img src={Motto} className="img" alt="" />
              </div>
              <span className="hashtag">#WarOnDrugs</span>
            </div>
            <div className="bottom">
              <p>
                Stadium Reborn Token ( $STD ) is here to fight all kinds of  illegal drugs and support the government in eradicating it.
              </p>
            </div>
          </div>
          <img src={SPT2} alt="" className="img-fluid" />
          <div className="social">
            <a className="item" href="https://twitter.com/stadiumreborn" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a className="item" href="https://mobile.facebook.com/BobbySuryadiStadium" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a className="item" href="https://www.instagram.com/stadiumreborn" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a className="item" href="https://t.me/StadiumReborn" target="_blank" rel="noopener noreferrer">
              <FaTelegram />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
