import { BGHero, LogoName, STDLogo } from '../../../assets';
import './Hero.scss';
const Hero = () => {
  return (
    <section className="_hero">
      <div className="floating-logo">
        <img src={STDLogo} alt="" className="logo-std" />
      </div>
      <div className="container-fluid">
        <div className="wrapper">
          <div className="row  align-items-center justify-content-center">
            <div className="col-lg-5 d-flex justify-content-center ill-hero">
              <div className="video-wrapper">
                <video playsInline autoPlay loop muted className="videoHero">
                  <source src={BGHero} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-lg-7">
              <div data-aos="fade-left" className="title-wrapper">
                <div className="title1">
                  <h1>Hi, my name is</h1>
                  <img src={LogoName} alt="" className="logo-name" />
                </div>
                <div className="title2">
                  <h1>And I'm proudly present <br /> <b>Stadium Reborn Token</b> ($STD)</h1>
                </div>
              </div>
              <div className="action-wrapper" data-aos="fade-up">
                <a href="https://t.me/StadiumReborn" target="_blank" className="btn-hero" rel="noopener noreferrer">
                  Telegram
                </a>
                <a href="#/" className="btn-hero ms-3">
                  Whitepaper
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>
  )
}

export default Hero
