import './Media.scss'
const Media = () => {
  return (
    <section className="_media" id="media">
      <div className="container">
        <div className="wrapper">
          <div className="title-page-1">
            <span>Media</span>
          </div>
          <div className="row justify-content-center media-wrapper">
            <div className="media-item col-3">
              <a href="https://hot.detik.com/music/d-5825688/stadium-reborn?_ga=2.50882345.1029186952.1639237210-1201800036.1635694607" target="_blank" rel="noopener noreferrer">
                <img src="/images/media/detikcom.png" alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Media
