const TokenAllocation = [
  // {
  //   id: 1,
  //   title: "TOTAL SUPPLY",
  //   val: "1.000.000.000.000 STD",
  // },
  {
    id: 2,
    title: "PRIVATE SALE",
    val: "10%",
  },
  {
    id: 3,
    title: "PUBLIC PRESALE",
    val: "40%",
  },
  {
    id: 4,
    title: "PANCAKESWAP",
    val: "28%",
  },
  {
    id: 5,
    title: "RESERVE EXCHANGE",
    val: "5%",
  },
  {
    id: 6,
    title: "DEVELOPER WALLET",
    val: "4%",
  },
  {
    id: 7,
    title: "MARKETING WALLET",
    val: "4%",
  },
  {
    id: 8,
    title: "RESEARCH AND DEVELOPMENT WALLET",
    val: "4%",
  },
  {
    id: 9,
    title: "CHARITY",
    val: "2%",
  },
  {
    id: 10,
    title: "BURN",
    val: "3%",
  },
];
const TokenDetail = [
  {
    id: 1,
    title: "SMART CONTRACT",
    val: "TBA",
  },
  {
    id: 2,
    title: "NAME",
    val: "STADIUM REBORN Token",
  },
  {
    id: 3,
    title: "SYMBOL",
    val: "STD",
  },
  {
    id: 4,
    title: "NETWORK",
    val: "Binance Smart Chain",
  },
  {
    id: 5,
    title: "Decimal",
    val: "9",
  },
  {
    id: 6,
    title: "Tax",
    val: "10% for every buy and sell",
  },
];

export { TokenAllocation,TokenDetail };
