import { Project2, Project3, Project4, RRave } from '../../../assets'
import './Projects.scss'
const Projects = () => {
  return (
    <section id="project" className="_project">
      <div className="container">
        <div className="wrapper">
          <div className="title-page-1">
            <span>Our Projects</span>
          </div>
          <div className="row mb-10 align-items-center justify-content-end">
            <div className="col-lg-6">
              <div className="product-wrapper" data-aos="zoom-in">
                <video playsInline autoPlay loop muted className="product-img">
                  <source src={RRave} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product-info-wrapper">
                <div className="title-1">
                  <h2 className="title"> Rave Party Event in various destinations in Indonesia</h2>
                  <p className="desc">
                    By holding a certain amount of Stadium Reborn Tokens ( $STD ) everyone has the opportunity to attend our rave party events. The rave party events will be attended by well-known Disc Jockeys from Indonesia.
                  </p>
                  <div className="ill-product" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-10 align-items-center justify-content-between">
            <div className="col-lg-6 order-1 order-lg-0">
              <div className="product-info-wrapper">
                <div className="title-1">
                  <h2 className="title">Tourism</h2>
                  <p className="desc">
                    Indonesia has many recognizable tourist destinations. Through Stadium Reborn Token ( $STD ), we want to blend blockchain technology and the natural beauty that Indonesia has to the world.
                  </p>
                  <div className="ill-product left" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="product-wrapper" data-aos="flip-down">
                <img src={Project2} alt="" className="product-img" />
              </div>
            </div>
          </div>
          <div className="row mb-10 align-items-center justify-content-end">
            <div className="col-lg-4 order-0 order-lg-1">
              <div className="product-wrapper" data-aos="flip-down">
                <img src={Project3} alt="" className="product-img" />
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="product-info-wrapper">
                <div className="title-1">
                  <h2 className="title">DJ-NFT</h2>
                  <p className="desc">
                    Collection of NFTs from iconic characters, legends, music, art in "Disc-Jockey Universe". Exclusively only on Stadium Reborn Token.
                  </p>
                  <div className="ill-product" />
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-0">
              <div className="product-info-wrapper">
                <div className="title-1">
                  <h2 className="title">CHARITY</h2>
                  <p className="desc">
                    Stadium Reborn Token ( $STD ) also has charity program to nurture humanity.
                  </p>
                  <div className="ill-product left" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-0 order-lg-1">
              <div className="product-wrapper">
                <div className="ill-image-product"></div>
                <img src={Project4} alt="" className="product-img" data-aos="zoom-in" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects
