import { ChartImage, SPT3 } from "../../../assets";
import { TokenAllocation, TokenDetail } from '../../../data/tokenomic';
import TokenDetails from "./TokenDetail";
import TokenItem from './TokenItem';
import './Tokenomics.scss';
const Tokenomics = () => {
  return (
    <section className="_tokenomics" id="tokenomic">
      <img src={SPT3} alt="" className="img-fluid w-100" style={{ height:'160px' }} data-aos="fade-down" />
      <div className="ring"></div>
      <div className="container-fluid">
        <div className="wrapper">
          <div className="title-page-1 mb-5">
            <span>Tokenomics</span>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="chartWrapper" data-aos="flip-right">
                <img src={ChartImage} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row token-detail">
                <div className="col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                  <span className="title">TOKEN ALLOCATION</span>
                  <div className="item">
                    <span className="name">TOTAL SUPPLY</span>
                    <span className="value"><b className="text-white">1.000.000.000.000 STD</b></span>
                  </div>
                  {
                    TokenAllocation.map(item => (
                      <TokenItem key={item.id} data={item} />
                    ))
                  }
                </div>
                <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                  <span className="title">TOKEN DETAILS</span>
                  {
                    TokenDetail.map(item => (
                      <TokenDetails key={item.id} data={item} />
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Tokenomics
