import { SPT2, Team1, Team2, Team3 } from '../../../assets'
import './Teams.scss'
const Teams = () => {
  return (
    <section className="_times" id="teams">
      <div className="container">
      <img src={SPT2} alt="" className="img-fluid"  data-aos="fade-up" />
        <div className="wrapper">
          <div className="title-page-1 my-5">
            <span>Teams</span>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <a href="https://www.instagram.com/bobbysuryadi" target="_blank" rel="noopener noreferrer">
                <div className="team-wrapper">
                  <div className="image-wrapper" data-aos="flip-right">
                    <img src={Team1} alt="" className="img" />
                  </div>
                  <div className="info-wrapper">
                    <div className="name">Bobby Suryadi</div>
                    <div className="job-title">CEO</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-3">
              <a href="https://www.instagram.com/just.ilung" target="_blank" rel="noopener noreferrer">
                <div className="team-wrapper">
                  <div className="image-wrapper" data-aos="flip-right" data-aos-delay="300">
                    <img src={Team2} alt="" className="img" />
                  </div>
                  <div className="info-wrapper">
                    <div className="name">MC. Basyar</div>
                    <div className="job-title">Advisor</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-3">
              <a href="https://instagram.com/hendyprbwo" target="_blank" rel="noopener noreferrer">
                <div className="team-wrapper">
                  <div className="image-wrapper" data-aos="flip-right" data-aos-delay="600">
                    <img src={Team3} alt="" className="img" />
                  </div>
                  <div className="info-wrapper">
                    <div className="name">Hendy (Ben)</div>
                    <div className="job-title">Developer</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Teams
