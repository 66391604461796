import { PARTBSC, PARTCMC, PARTGECKO, PARTPANCAKE } from '../../../assets'
import './Partners.scss'
const Partners = () => {
  return (
    <div className="_partner">
      <div className="container">
        <div className="wrapper">
        <div className="title-page-1">
            <span>Soon Live</span>
          </div>
          <div className="row mb-4 justify-content-center">
            <div className="col-lg-2">
              <div className="coin-item">
                <img src={PARTBSC} alt="" className="icon img-fluid" />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="coin-item">
                <img src={PARTPANCAKE} alt="" className="icon img-fluid" />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="coin-item">
                <img src={PARTCMC} alt="" className="icon img-fluid" />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="coin-item">
                <img src={PARTGECKO} alt="" className="icon img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
