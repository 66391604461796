import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Particles from "react-tsparticles";
import { About, Footer, Hero, Media, Partners, Projects, RoadMap, Teams, Tokenomics } from "./components/organisms";
import paticleOption from "./data/particle";

function App() {
  useEffect(() => {
    Aos.init({
      duration:1000
    });
  }, [])
  const particlesInit = (main) => {
    console.log(main);
  };
  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <div className="app">
      <Hero />
      <About />
      <Projects />
      <Tokenomics />
      <RoadMap />
      <Teams />
      <Media />
      <Partners />
      <Footer />
      <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={paticleOption} />
    </div>
  );
}

export default App;
