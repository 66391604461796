import './RoadMap.scss'
const RoadMap = () => {
  return (
    <section className="_roadmap" id="roadmap">
      <div className="container">
        <div className="wrapper">
          <div className="title-page-1 mb-5">
            <span>Roadmap</span>
          </div>
          <div className="row">
            <div className="col-12 line">
              <div className="row  justify-content-center">
                <div className="col-lg-3" data-aos="fade-right"
                  data-aos-duration="200">
                  <div className="yearItem">2021</div>
                  <div className="q">
                    <div className="title">Q4</div>
                    <ul>
                      <li>Website Development</li>
                      <li>Whitepaper</li>
                      <li>Private Sale & Presale</li>
                      <li>Huge marketing plan before launch</li>
                      <li>Launch on Pancakeswap ( DEX )</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3" data-aos="fade-right"
                  data-aos-duration="400">
                  <div className="yearItem">2022</div>
                  <div className="q">
                    <div className="title">Q1</div>
                    <ul>
                      <li>Listing On CoinGecko</li>
                      <li>Listing On CoinMarketCap</li>
                      <li>Logo On Web3 Wallet</li>
                      <li>Contract Audit Expand</li>
                      <li>Rundown Rave Party Event EVERY MONTH</li>
                      <li>Exchange Listing ( CEX )</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3" data-aos="fade-right"
                  data-aos-duration="600">
                  <div className="yearItem">2022</div>
                  <div className="q">
                    <div className="title">Q2</div>
                    <ul>
                      <li>Certik Audit</li>
                      <li>Partnership & Marketing Strategy</li>
                      <li>DJ-NFT Collectibles</li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-lg-9 d-none d-lg-block">
                  <div className="cardLineTime">
                    <div className="line-zero">
                      <div className="box" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RoadMap
