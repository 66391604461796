const TokenDetails = ({data}) => {
  return (
    <div className="item">
      <span className="name">{data.title}</span>
      <span className="value">{data.val}</span>
    </div>
  )
}

export default TokenDetails
