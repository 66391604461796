const paticleOption = {
  background: {
    color: {
      value: '#000',
    },
  },
  fpsLimit: 60,
  interactivity: {
    events: {
      onClick: {
        enable: true,
        mode: 'push',
      },
      onHover: {
        enable: true,
        mode: 'repulse',
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 2,
        opacity: 0.01,
        size: 8,
      },
      push: {
        quantity: 1,
      },
      repulse: {
        distance: 100,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: ['#FFF', 'red'],
    },
    links: {
      color: '#000',
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 0.5,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: false,
      speed: 0,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        value_area: 500,
      },
      value: 180,
    },
    opacity: {
      value: 0.5,
      anim: {
        enable: true,
        speed: 2,
        opacity_min: 0,
        sync: false,
      },
    },
    shape: {
      type: 'circle',
      width: 0,
      color: '#000000',
    },
    size: {
      random: true,
      value: 2,
    },
  },
  detectRetina: true,
}

export default paticleOption