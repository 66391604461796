import { IllAbout, SPT1, SPT7 } from '../../../assets'
import './About.scss'
const About = () => {
  return (
    <section className="_about" id="about">
      <div className="container">
          <img src={SPT1} alt="" className="img-fluid"  data-aos="fade-up" />
        <div className="wrapper">
          <div className="title-page-1" data-aos="fade-down">
            <span>About Us</span>
          </div>
          <div className="ill-about"></div>
          <div className="ill-about-2"></div>
          <div className="ill-about-3">
            <img src={SPT7} alt="" />
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div className="about-container" data-aos="fade-right">
                <div className="item">
                  <h2 className="title">What is STADIUM REBORN ?</h2>
                  <p className="desc">
                    Stadium Reborn is blockchain’s real-life application and Powered rave party events in Indonesia. Stadium Reborn’s core values are supporting Indonesian Disc Jockeys and at the same time initiate rave party events to increase tourism visits to various destinations in Indonesia
                  </p>
                </div>
                <div className="item">
                  <h2 className="title">Vision</h2>
                  <p className="desc">
                    Our goal is to encourage more people into the world of crypto assets by creating event that people can interact and participate utilizing blockchain technology.
                  </p>
                </div>
                <div className="item">
                  <h2 className="title">Mission</h2>
                  <p className="desc">
                    Appreciate Disc Jockey profession, especially the talents and legends from Indonesia. Our main mission is to make local Disc Jockeys and Indonesian tourism to be more recognized around the world.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ill-about-bg">
              <div className="image-wrapper">
                <img src={IllAbout} alt="img" className="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
